/* CSS Document */

a {
    text-decoration: none
}

.btn {
    padding: 10px 45px;
    font-weight: 500;
}

input[type=text] {
    border-color: grey;
    padding: 12px;
}

.alert-dark {
    background-color: $gray-800;
    border: none;
}

.barebone-header img {
    max-width: 15.625rem;
}

form {
    background-color: $gray-400;
}

.form-control {
    border-color: $gray-700;
}


footer {
background-color: $gray-400

}